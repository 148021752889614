import React from 'react';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="privacy-wrapper">
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> February 2024
        </p>

        <h2>1. Introduction</h2>
        <p>
          Blissbits Limited ("Blissbits," "we," "our," or "us") operates the
          Accountability Squad app ("ASquad") and values your privacy. We are
          committed to protecting your personal data. This Privacy Policy
          outlines how we collect, use, and share your information when you use
          our app, website, and services. By using ASquad, you agree to the
          practices described in this Privacy Policy.
        </p>

        <h2>2. Information We Collect</h2>
        <h3>a. Information You Provide Directly</h3>
        <ul>
          <li>
            <strong>Account Information:</strong> When you create an account, we
            collect your name, email address, and password.
          </li>
          <li>
            <strong>Profile Information:</strong> Optional details like your
            profile picture, learning goals, and group affiliations.
          </li>
          <li>
            <strong>Goals and Progress Data:</strong> Information you input,
            such as goals, subtasks, and cycles.
          </li>
        </ul>

        <h3>b. Automatically Collected Information</h3>
        <ul>
          <li>
            <strong>Device Information:</strong> Includes your device type,
            operating system, and app version.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect data on how you interact
            with the app, such as which features you use.
          </li>
          <li>
            <strong>Log Data:</strong> IP address, time and date of use, and app
            crash reports.
          </li>
        </ul>

        <h3>c. Third-Party Services</h3>
        <p>
          If you use third-party services to sign in (e.g., Google), we collect
          information provided by those services as per their privacy policies.
        </p>

        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>
            <strong>To Provide the Service:</strong> Manage your account, track
            goals, monitor cycles, and send reminders.
          </li>
          <li>
            <strong>To Improve the App:</strong> Analyze usage data and feedback
            to improve user experience.
          </li>
          <li>
            <strong>To Communicate With You:</strong> Send notifications about
            your goals, cycles, and app updates.
          </li>
          <li>
            <strong>To Ensure Security:</strong> Protect against unauthorized
            access and maintain app integrity.
          </li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal data. We may share your data in
          the following cases:
        </p>
        <ul>
          <li>
            <strong>With Group Members:</strong> Your progress and goals may be
            visible to others in your accountability group.
          </li>
          <li>
            <strong>With Service Providers:</strong> Third-party services that
            assist in hosting, analytics, or push notifications.
          </li>
          <li>
            <strong>To Comply with Legal Obligations:</strong> When required by
            law or to protect our rights.
          </li>
        </ul>

        <h2>5. Data Retention</h2>
        <p>
          We retain your data as long as necessary to provide the service. If
          you delete your account, we will remove your personal information,
          though anonymized usage data may be retained for analysis.
        </p>

        <h2>6. Security</h2>
        <p>
          We use industry-standard measures to protect your data. However, no
          system is completely secure, and we cannot guarantee absolute
          security.
        </p>

        <h2>7. Your Choices</h2>
        <ul>
          <li>
            <strong>Access and Update Information:</strong> You can update your
            profile and goal data via the app.
          </li>
          <li>
            <strong>Push Notifications:</strong> You can enable or disable push
            notifications in the app settings.
          </li>
          <li>
            <strong>Account Deletion:</strong> You may delete your account by
            contacting support at{' '}
            <a href="mailto:team@asquadapp.com">team@asquadapp.com</a>.
          </li>
        </ul>

        <h2>8. Third-Party Links</h2>
        <p>
          Our platform may link to external websites or services. We are not
          responsible for the privacy practices of these third-party services.
        </p>

        <h2>9. Children's Privacy</h2>
        <p>
          ASquad is not intended for individuals under the age of 13. We do not
          knowingly collect personal information from children.
        </p>

        <h2>10. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with the updated effective date.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          your data, contact us at:
        </p>
        <p>
          <strong>Email:</strong>{' '}
          <a href="mailto:team@asquadapp.com">team@asquadapp.com</a>
          <br />
          <strong>Website:</strong> asquad.app
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './GridCarousel.css';
const images = [
  {
    id: 1,
    src: 'img/book1.jpg',
    title: 'Smarter faster better',
    description:
      'Smarter Faster Better: The Secrets of Being Productive in Life and Business - by Charles Duhigg Another book by Duhigg that delves into productivity, it discusses the concept of creating mental models and accountability systems to improve decision-making and goal attainment.',
  },
  {
    id: 2,
    src: 'img/book2.jpg',
    title: 'The 4 hour work week',
    description:
      'The 4-Hour Workweek: Escape 9-5, Live Anywhere, and Join the New Rich  by Timothy Ferriss - Tim Ferriss talks about accountability systems, such as setting up stakes and public commitments, to ensure you stay focused and motivated.',
  },
  {
    id: 3,
    src: 'img/book3.jpg',
    title: 'The One thing ',
    description:
      'The One Thing: The Surprisingly Simple Truth Behind Extraordinary Results - by Gary Keller and Jay Papasan - This book stresses focusing on the most important task and mentions how having accountability partners can help in maintaining focus and achieving those critical tasks.',
  },
  {
    id: 4,
    src: 'img/book4.jpg',
    title: 'Getting things done',
    description:
      'Getting Things Done: The Art of Stress-Free Productivity by David Allen - David Allen discusses various productivity techniques, including the importance of accountability partners and regular reviews to stay on track with your goals.',
  },
  {
    id: 5,
    src: 'img/book5.jpg',
    title: 'Deep work',
    description:
      'Deep Work: Rules for Focused Success in a Distracted World  by Cal Newport - While primarily focused on the importance of deep work, Newport also mentions the role of accountability in creating an environment conducive to achieving significant goals.',
  },
  {
    id: 6,
    src: 'img/book6.jpg',
    title: 'The Power of Habit',
    description:
      'The Power of Habit: Why We Do What We Do in Life and Business by Charles Duhigg This book explores the science behind habits and how they can be changed. It emphasizes the role of social accountability in forming and maintaining new habits.',
  },
  {
    id: 7,
    src: 'img/book7.jpg',
    title: 'Drive',
    description:
      'Drive: The Surprising Truth About What Motivates Us by Daniel H. Pink - Pink explores the elements of intrinsic motivation and how having accountability structures in place can enhance motivation and goal achievement.',
  },
  {
    id: 8,
    src: 'img/book8.jpg',
    title: 'Essentialism',
    description:
      'Essentialism: The Disciplined Pursuit of Less by Greg McKeown This book emphasizes focusing on what truly matters and suggests accountability mechanisms to keep you aligned with your essential goals.',
  },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const GridCarousel = () => {
  return (
    <div className="grid-carousel">
      <h3 className="carousel-title">
        How leading authors view{' '}
        <span className="underline-magical">Accountability</span> in goal
        setting
      </h3>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {images.map((image) => (
          <div key={image.id} className="carousel-item">
            <img src={image.src} alt={image.title} className="carousel-image" />
            <p className="carousel-title">{image.title}</p>
            <p className="carousel-description">{image.description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default GridCarousel;

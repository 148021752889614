import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Container, Form, Button } from 'react-bootstrap';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin } from '@react-oauth/google';
import Spinner from 'react-bootstrap/Spinner';
import { useGoogleLogin } from '@react-oauth/google';

const apiUrl = process.env.REACT_APP_API_URL;

const SignUpForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [gloading, setGLoading] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const { setUserId } = useContext(UserContext);
  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      setGLoading(true);
      try {
        const res = await axios.post(
          `${apiUrl}/users/google-register-custom-btn`,
          {
            response: credentialResponse,
          },
        );
        //    console.log("responsse:: ");
        const token = res.data.token;
        //  console.log(JSON.stringify(res.data));
        localStorage.setItem('token', token); //
        const userId = res.data.userId; // Assuming the API response contains the adminId
        localStorage.setItem('userId', userId);
        setUserId(userId);

        navigate('/finish-setup');
      } catch (error) {
        console.log('We are facing this error: ' + error);
        console.log('We are facing this error: ' + error.response);
        console.log(
          'error.response.data.message' +
            JSON.stringify(error.response.data.message),
        );
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        }
      } finally {
        setGLoading(false);
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    // Password validation
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const user = {
      name: name,
      email: email,
      password: password,
    };

    try {
      setLoading(true); // Set loading to true
      const response = await axios.post(`${apiUrl}/users`, user);
      const token = response.data.token;

      localStorage.setItem('token', token); //
      const userId = response.data.userId; // Assuming the API response contains the adminId
      localStorage.setItem('userId', userId);
      setUserId(userId);
      localStorage.setItem('hasSeenPopup', false);
      navigate('/finish-setup');
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
      }
      // console.log("error.response.data.errors: ");
      // console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup </title>
        <meta name="description" content="Asquad - accountability made easy" />
      </Helmet>
      <Container fluid fill>
        <div className="container-wrapper align-items-center">
          <div className="signup-wrapper">
            <Form className="FormContainer">
              <img
                src={process.env.PUBLIC_URL + '/img/asquads.png'}
                alt="Logo"
              />
              <div className="tagline">
                <p>Accountability made easy.</p>
              </div>
              <div className="google-auth"></div>

              <Form.Group className="mb-3" controlId="formName">
                <Form.Control
                  className="form-rounded"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control
                  className="form-rounded"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  className="form-rounded"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 confirmPassword"
                controlId="formBasicPassword2"
              >
                <Form.Control
                  className="form-rounded"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
              </Form.Group>
              {error && <p className="error-message">{error}</p>}
              <Button
                className="fullwidth"
                variant="primary"
                onClick={handleSubmit}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Loading...
                  </>
                ) : (
                  'Sign Up'
                )}
              </Button>
              <div className="divider">
                {' '}
                <span className="line-grey"> </span> or
                <span className="line-grey"> </span>{' '}
              </div>
            </Form>
            <div className="googleBtn" onClick={() => login()}>
              {gloading
                ? null
                : // Loading spinner while API call is in progress
                  'Continue with Google'}
            </div>

            <div className="al">
              <p
                style={{
                  paddingTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '13px',
                }}
              >
                Already have an account?{' '}
                <p className="subtext-login" onClick={navigateToLogin}>
                  {' '}
                  Login now
                </p>{' '}
              </p>
            </div>
          </div>
        </div>

        {/* Fullscreen loading overlay */}
        {(loading || gloading) && (
          <div className="loading-overlay">
            <div className="loading-text">Loading...</div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SignUpForm;

import React from 'react';
import { Col, Row } from 'react-bootstrap';

const FooterLanding = () => {
  return (
    <>
      <Row className="pb-5 footer-main">
        <Col xs={12} md={6}>
          {' '}
          <h5 style={{ paddingBottom: '20px' }}>Menu</h5>
          <p>
            <a
              href="mailto:naveenkolambage@gmail.com"
              target="_blank"
              rel="noopener"
            >
              {' '}
              Enquire
            </a>
          </p>
          <p>
            {' '}
            <a
              href="https://www.asquad.app/login"
              target="_blank"
              rel="noopener"
            >
              {' '}
              Login
            </a>
          </p>
          <p>
            <a
              href="https://www.asquad.app/signup"
              target="_blank"
              rel="noopener"
            >
              {' '}
              Register for free
            </a>
          </p>
        </Col>
        <Col xs={12} md={6}>
          <h5 style={{ paddingBottom: '20px' }}>Community</h5>
          <p>
            <a
              href="https://discord.gg/hnJJrZpb"
              target="_blank"
              rel="noopener"
            >
              {' '}
              Discord
            </a>
          </p>
          <p>
            <a
              href="https://asquad.app/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              {' '}
              Privacy Policy
            </a>
          </p>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '0px',
          color: 'black',
        }}
      >
        <p
          className="copyright"
          style={{ textAlign: 'center', fontSize: '11px' }}
        >
          {' '}
          Copyright © 2024 Asquad - Developed and operated by BlissBits Ltd.
        </p>
        <p style={{ textAlign: 'center', fontSize: '11' }}> </p>
      </Row>
    </>
  );
};

export default FooterLanding;

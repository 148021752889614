import { useState, useContext } from 'react';
import AppleLogin from 'react-apple-login';
import { UserContext } from '../components/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
const appleClientId = process.env.REACT_APP_WEB_APPLE_CLIENT_ID;
const appleRedirectURI = process.env.REACT_APP_WEB_APPLE_REDIRECT_URI;

const TestAppleSignin = () => {
  const [loading, setLoading] = useState(false);
  const { setUserId } = useContext(UserContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleAppleLogin = async (response) => {
    const { code, id_token } = response;

    setLoading(true);
    try {
      const res = await axios.post(`${apiUrl}/users/apple-login`, {
        code,
        id_token,
      });

      const token = res.data.token;
      localStorage.setItem('token', token);
      const userId = res.data.userId;
      localStorage.setItem('userId', userId);
      setUserId(userId);

      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <AppleLogin
        callback={handleAppleLogin}
        clientId={appleClientId}
        redirectURI={appleRedirectURI}
      />
    </>
  );
};

export default TestAppleSignin;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import axios from 'axios';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import {
  CheckCircle,
  GroupAdd,
  PeopleAlt,
  Group,
  EventNote,
} from '@mui/icons-material';
const apiUrl = process.env.REACT_APP_API_URL;

const WelcomePopup = ({ open, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  // Icons for individual
  const individualIcons = [
    <GroupAdd />,
    <PeopleAlt />,
    <EventNote />,
    <CheckCircle />,
  ];

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    setUserId(userId);
    setToken(token);
  }, []);

  // Icons for group
  const groupIcons = [<Group />, <PeopleAlt />, <EventNote />, <CheckCircle />];
  const stepsIndividualDescription = [
    'Go to Browse Groups to find and join a group that fits your interests.',
    'Connect with your team and let them know you’ve joined.',
    'Set goals together and work toward them as a team.',
    'Celebrate each other’s achievements and progress.',
  ];

  const stepsGroupDescription = [
    'Go to Create Group and enter group details. Choose to make it public or private as appropriate.',
    'Invite current group members by adding their email addresses.',
    'Set up goal cycles to guide your group’s progress.',
    'Review goal cycles regularly to keep everyone accountable.',
  ];

  const stepsTitleIndividual = [
    'Browse',
    'Connect',
    'Set Goals',
    'Celebrate your progress!',
  ];

  const stepsTitleGroup = [
    'Create Group',
    'Invite Members',
    'Set Goals',
    'Celebrate your progress!',
  ];

  const handleGotItClick = async () => {
    try {
      console.log('userId');
      console.log(userId);
      // Send the POST request to mark the popup as seen
      const res = await axios.post(
        `${apiUrl}/users/${userId}/popup`,
        {},
        {
          headers: {
            'x-auth-token': token,
          },
        },
      );
      console.log('res.data: ', res.data);
      // Call onClose to close the popup
      onClose();
    } catch (error) {
      console.error('Error marking popup as seen:', error);
      // Optional: Handle error (e.g., show an error message)
    }
  };

  const iconStyles = { color: '#58ae22', marginRight: '8px' };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const renderSteps = (
    titles,
    descriptions,
    IconArray,
    iconColor = '#58ae22',
  ) =>
    descriptions.map((desc, index) => (
      <Box
        key={index}
        display="flex"
        bgcolor="#dbf6ca"
        borderRadius="8px"
        p={2}
        my={1}
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
      >
        {/* Icon Container */}
        <Box style={{ marginRight: '10px' }}>
          {/* Set font size for the icon */}
          {React.cloneElement(IconArray[index], {
            fontSize: '18px',

            style: { color: iconColor },
          })}
        </Box>

        {/* Text Container */}
        <Box>
          <Typography
            variant="subtitle1"
            color="#58ae22"
            fontWeight="bold"
            fontSize="12px"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {titles[index]}
          </Typography>
          <Typography variant="body2" color="gray" fontSize="12px">
            {desc}
          </Typography>
        </Box>
      </Box>
    ));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      style={{ height: '950px' }}
    >
      <DialogTitle
        className="popupHeader"
        style={{
          fontSize: '1.2rem',
          color: '#2a9d29',
          textAlign: 'center',
          //   background: 'linear-gradient(135deg, #58ae22, #a6d96e)', // Linear gradient background
          position: 'relative', // To position the close button relative to the title
          paddingRight: '40px', // Space for the close icon
          paddingLeft: '20px', // Optional padding

          // Optional rounded top corners for a smoother look
        }}
      >
        How it works
        {/* Close Icon positioned on the top-right corner */}
        <IconButton
          onClick={onClose} // Ensure to handle the close functionality here
          style={{
            edge: 'end',
            position: 'absolute',
            top: '10px',
            right: '10px',

            color: 'green', // Set close icon color to white
          }}
        >
          <Close style={{ fontSize: '1rem' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ height: '450px', overflowY: 'auto' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab
            label="Individual"
            style={{
              color: '#58ae22',
              color: tabIndex === 0 ? '#58ae22' : '#999',

              fontSize: '12px', // Customize font size
              // Set active/inactive color
              textTransform: 'none', // Remove uppercase transformation
            }}
          />
          <Tab
            label="Existing Groups"
            style={{
              color: '#58ae22',
              color: tabIndex === 1 ? '#58ae22' : '#999',

              fontSize: '12px', // Customize font size
              // Set active/inactive color
              textTransform: 'none', // Remove uppercase transformation
            }}
          />
        </Tabs>

        {/* Individual Tab */}
        {tabIndex === 0 && (
          <Box mt={2} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            {renderSteps(
              stepsTitleIndividual,
              stepsIndividualDescription,
              individualIcons,
            )}
          </Box>
        )}

        {/* Group Lead Tab */}
        {tabIndex === 1 && (
          <Box mt={2} style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            {renderSteps(stepsTitleGroup, stepsGroupDescription, groupIcons)}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Box
          display="flex"
          justifyContent="center" // Center the button horizontally
          width="100%" // Take full width to align with the boxes
          // Optional: Add margin-top to give space above the button
        >
          <Button
            variant="contained"
            onClick={handleGotItClick}
            style={{
              width: '100%',
              maxWidth: '330px',
              marginBottom: '50px',
              textTransform: 'none',
            }} // Adjust button width and padding,
          >
            Got It
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomePopup;

import { useState, useContext } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { UserContext } from './UserContext';
import Button from 'react-bootstrap/Button';
import MainLayout from './MainLayout';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
const apiUrl = process.env.REACT_APP_API_URL;
const appleClientId = process.env.REACT_APP_WEB_APPLE_CLIENT_ID;
const appleRedirectURI = process.env.REACT_APP_WEB_APPLE_REDIRECT_URI;

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setUserId } = useContext(UserContext);

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      setLoading(true);
      try {
        const res = await axios.post(
          `${apiUrl}/users/google-login-custom-btn`,
          {
            response: credentialResponse,
          },
        );
        //    console.log("responsse:: ");
        const token = res.data.token;
        //  console.log(JSON.stringify(res.data));
        localStorage.setItem('token', token); //
        const userId = res.data.userId; // Assuming the API response contains the adminId
        localStorage.setItem('userId', userId);
        setUserId(userId);

        navigate('/dashboard');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email, password);
    setLoading(true);
    const user = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(`${apiUrl}/users/normal-login`, user);
      console.log(response);
      const token = response.data.token;
      console.log('Tokenfornormal', token);
      localStorage.setItem('token', token); //

      const userId = response.data.userId; // Assuming the API response contains the adminId
      localStorage.setItem('userId', userId);
      setUserId(userId);

      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setError('Oops, Please check your credentials ');
    } finally {
      setLoading(false);
    }
  };
  const handleForgotPassword = () => {
    navigate('/reset');
  };

  const handleAppleLogin = async (response) => {
    const { code, id_token } = response;

    setLoading(true);
    try {
      const res = await axios.post(`${apiUrl}/users/apple-login`, {
        code,
        id_token,
      });

      const token = res.data.token;
      localStorage.setItem('token', token);
      const userId = res.data.userId;
      localStorage.setItem('userId', userId);
      setUserId(userId);

      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const navigateToSignup = () => {
    // 👇️ navigate to /contacts
    navigate('/signup');

    //
  };
  return (
    <>
      {' '}
      <Helmet>
        <title>Login </title>
        <meta name="description" content="Asquad - accountability made easy" />
      </Helmet>
      <Container fluid fill>
        <div className="container-wrapper align-items-center">
          <div className="signup-wrapper">
            <Form className="FormContainer">
              <img
                src={process.env.PUBLIC_URL + '/img/asquads.png'}
                alt="Logo"
              />
              <div className="tagline">
                <p>Accountability made easy.</p>
              </div>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Group>
              {error && <div className="error-message">{error}</div>}{' '}
              <Button
                className="fullwidth"
                variant="primary"
                onClick={handleSubmit}
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Loading...
                  </>
                ) : (
                  'Sign in'
                )}
              </Button>
              <div className="fpwd pt-2 text-center tagline">
                <p onClick={handleForgotPassword}> Forgot Password?</p>
              </div>
              <div className="divider">
                {' '}
                <span className="line-grey"> </span> or
                <span className="line-grey"> </span>{' '}
              </div>
            </Form>
            <div className="googleBtn" onClick={() => login()}>
              Continue with Google
            </div>

            <div className="al">
              <p
                style={{
                  paddingTop: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '13px',
                }}
              >
                {' '}
                Don't have an account?{' '}
                <p className="subtext-login" onClick={navigateToSignup}>
                  {' '}
                  Sign up
                </p>{' '}
              </p>
            </div>
          </div>
        </div>

        {loading && (
          <div className="loading-overlay">
            <div className="loading-text">Loading...</div>
          </div>
        )}
      </Container>
    </>
  );
};

export default LoginForm;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Grid,
} from '@mui/material';
import { FaRegUserCircle } from 'react-icons/fa';
import axios from 'axios'; // Import axios for API requests
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

const FinishSettingUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const navigate = useNavigate();
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  // const [timeZones, setTimeZones] = useState([]);
  const timeZones = moment.tz.names();

  const [profileImage, setProfileImage] = useState('');
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  // Handle image upload and send to server
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Display the selected image locally
      setImage(URL.createObjectURL(file));

      // Send the image to the backend
      const formData = new FormData();
      formData.append('image', file);
      //   formData.append('file', file);

      try {
        const response = await axios.post(
          `${apiUrl}/users/upload-profile-image`,
          formData,
        );

        // Update image state with the new URL from server response
        console.log(response.data);
        if (response.data.imageUrl) {
          setImage(response.data.imageUrl);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate required fields
      if (!timeZone || !firstName || !lastName) {
        alert('Please fill in all the fields.');
        return;
      }

      // Prepare user details for update
      const name = firstName;
      const lastname = lastName;
      const confirmedStatus = true;

      // More comprehensive mobile detection
      const isMobile =
        /Mobi|Android/i.test(navigator.userAgent) ||
        window.matchMedia('(max-width: 767px)').matches;

      const isWebView = /WebView/i.test(navigator.userAgent);

      console.log('navigator.userAgent: ', navigator.userAgent);
      // Detailed logging for platform detection
      console.log('Platform detection:', {
        isMobile,
        userAgent: navigator.userAgent,
        screenWidth: window.screen.width,
      });

      // Update user details
      const response = await axios.put(
        `${apiUrl}/users/${userId}/update-user-details`,
        {
          name,
          lastname,
          image,
          confirmedStatus,
          timeZone,
        },
        {
          headers: {
            'x-auth-token': token,
          },
        },
      );

      // Log successful response
      console.log('User update response:', response.data);

      // Handle deep linking for mobile
      if (isMobile && navigator.userAgent.includes('wv')) {
        try {
          // Generate a unique session token
          const sessionToken =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

          // Construct deep link with additional parameters
          const deepLink = `myapp://signup-success?access_token=${token}&user_id=${userId}&session_token=${sessionToken}`;

          // Attempt deep linking
          window.location.replace(deepLink);

          // Fallback method
          setTimeout(() => {
            window.open(deepLink, '_self');
          }, 300);
        } catch (error) {
          console.error('Deep link error:', error);

          // Fallback web navigation
          navigate('/dashboard');
        }
      } else {
        // Web navigation for non-mobile platforms
        navigate('/dashboard');
      }
    } catch (error) {
      // Comprehensive error handling
      console.error('Error updating user details:', error);

      // Check if error is from axios
      if (error.response) {
        // The request was made and the server responded with a status code
        alert(
          `Update failed: ${error.response.data.message || 'Server error'}`,
        );
      } else if (error.request) {
        // The request was made but no response was received
        alert(
          'No response received from server. Please check your connection.',
        );
      } else {
        // Something happened in setting up the request
        alert('Error: ' + error.message);
      }
    } finally {
      // Ensure loading state is always set to false
      setLoading(false);
    }
  };
  const handleGoalSelection = (e) => {
    const value = e.target.value;
    setSelectedGoals((prevGoals) =>
      prevGoals.includes(value)
        ? prevGoals.filter((goal) => goal !== value)
        : [...prevGoals, value],
    );
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/users/${userId}/user-details`,
          {
            headers: {
              'x-auth-token': token, // Pass the auth token in the headers
            },
          },
        );
        console.log('response-data- ', response);
        // Assuming response.data contains firstName, lastName, profileImage, and email
        setFirstName(response.data.name || '');
        setLastName(response.data.lastname || '');
        setImage(response.data.profileImageUrl || '');
        setEmail(response.data.email || '');

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timeZones.includes(userTimeZone)) {
          setTimeZone(userTimeZone);
        }

        // setEmail(response.data.email || ''); // Uncomment if you want to set the email
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    // const fetchTimeZones = async () => {
    //   try {
    //     const response = await axios.get(
    //       'http://worldtimeapi.org/api/timezone',
    //     );
    //     setTimeZones(response.data);
    //   } catch (error) {
    //     console.error('Error fetching time zones:', error);
    //   }
    // };
    if (userId && token) {
      fetchUserDetails();
      // fetchTimeZones();
    }
  }, [userId, token]); // Re-run the effect if userId or token changes

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 2,
        padding: 2,
        maxWidth: 400,
        margin: '0 auto',
      }}
    >
      <p
        style={{
          fontSize: '16px',
          color: 'green',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        Finish Setting Up..
      </p>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <label htmlFor="profileImage">
          <div
            style={{
              cursor: 'pointer',
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {image ? (
              <img
                src={image}
                alt="Profile"
                //  style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                style={{ width: '100%', height: 'auto', borderRadius: '50%' }}
              />
            ) : (
              <FaRegUserCircle size={60} />
            )}
          </div>
          <p style={{ fontSize: '12px', textAlign: 'center' }}>
            {image ? 'Change Image' : 'Add Image'}
          </p>
        </label>
        <input
          type="file"
          id="profileImage"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </Box>

      <Box
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <FormControl fullWidth required>
          <InputLabel>Time Zone</InputLabel>
          <Select
            value={timeZone}
            onChange={(e) => setTimeZone(e.target.value)}
            label="Time Zone"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Set a maximum height for the dropdown
                  overflow: 'auto',
                  width: '20%', // Enable scroll if the content overflows
                },
              },
            }}
          >
            <MenuItem value="">Select Time Zone</MenuItem>
            {timeZones.map((zone, index) => (
              <MenuItem key={index} value={zone}>
                {zone} ({moment.tz(zone).format('UTCZ')})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ marginTop: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isConfirmed}
                onChange={() => setIsConfirmed(!isConfirmed)}
              />
            }
            label="I confirm that"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: '12px', // Replace with your desired font family
              },
            }}
          />
          <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
            <li style={{ fontSize: '12px' }}>I am at least 17 years old</li>
            <li style={{ fontSize: '12px' }}>
              I accept and agree to Asquad's terms and{' '}
              <a
                href="https://asquad.app/privacy-policy"
                target="_blank"
                rel="noopener"
              >
                {' '}
                Privacy Policy
              </a>
            </li>
          </ul>
        </Box>
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isConfirmed || !firstName || !lastName || !timeZone}
          sx={{ marginTop: 2 }}
        >
          Complete Signup
        </Button>
        <Box sx={{ marginTop: 2, textAlign: 'center' }}>
          <p className="tw-size">Signing up as {email}</p>
          <p
            className="tw-size"
            onClick={() => alert('Go back to signup screen')}
          >
            Change email
          </p>
        </Box>
      </Box>
      {/* Fullscreen loading overlay */}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-text">Loading...</div>
        </div>
      )}
    </Box>
  );
};

export default FinishSettingUp;

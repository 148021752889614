import React, { useEffect, useState, useContext } from 'react';
import SideNav from '../components/SideNav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Dashboard.css';
import DashboardHeaderColumn from './DashboardHeaderColumn';
import DashboardTable from './DashboardTable';
import Form from 'react-bootstrap/Form';
import { UserContext } from '../components/UserContext';
import axios from 'axios';
import { Watch } from 'react-loader-spinner';
import WelcomePopup from './WelcomePopup';
import MobileBanner from '../components/MobileBanner';

const apiUrl = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const [overallProgress, setOverallProgress] = useState();
  const [noOfCycles, setNoOfCycles] = useState();
  const [totalGroups, setTotalGroups] = useState();
  const [isAllCycles, setIsAllCycles] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const { userName } = useContext(UserContext);
  const handleSwitchChange = () => {
    setIsAllCycles(!isAllCycles); // Toggle the state
  };
  const handlePopupClose = async () => {
    setShowPopup(false); // Close popup when "GOT IT" is clicked
    // localStorage.setItem('hasSeenPopup', 'true'); // Remember user has seen the popup
    // Send POST request to update popup status in the backend
  };

  useEffect(() => {
    setLoading(true);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    const fetchData = async () => {
      try {
        // If localStorage already has the value, check if popup was seen

        const first_response = await axios.get(
          `${apiUrl}/users/get_overall_progress/${userId}`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );

        setOverallProgress(first_response.data);

        const second_response = await axios.get(
          `${apiUrl}/cycles/numberofactive`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );

        console.log('second_response:: ', second_response);
        setNoOfCycles(second_response.data);

        const third_response = await axios.get(
          `${apiUrl}/users/${userId}/my-total-groups`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );

        /// console.log('third_response:: ', third_response.data);

        setTotalGroups(third_response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        console.log('loading: ', loading);
      }
    };

    fetchData();
    console.log('LOADING STATE: ', loading);
  }, []);

  useEffect(() => {
    const checkPopupStatus = async () => {
      // Check if the popup status is in localStorage first
      //  const hasSeenPopup = localStorage.getItem('hasSeenPopup');
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      setIsMobile(isMobile);
      // If not set in localStorage, fetch from the backend
      if (showPopup === null) {
        console.log('CHECK');
        try {
          const response = await axios.get(
            `${apiUrl}/users/${userId}/check-popupstatus`,
            { headers: { 'x-auth-token': token } },
          );
          const popupSeen = response.data.welcomePopupSeen ? 'true' : 'false';
          //localStorage.setItem('hasSeenPopup', popupSeen);
          console.log('Popup');
          // Show popup if not seen
          if (popupSeen === 'false') {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        } catch (error) {
          console.error('Error fetching popup status:', error);
        }
      } else {
        // If popup status is already in localStorage
        //setShowPopup(hasSeenPopup === 'false');
      }
    };

    checkPopupStatus(); // Call the function to check the popup status
  }, []); // Empty d
  return (
    <>
      {showPopup && (
        <WelcomePopup open={showPopup} onClose={handlePopupClose} />
      )}
      {isMobile && <MobileBanner />}
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col>
          {loading ? (
            <>
              <div className="d-flex justify-content-center align-items-center vh-75">
                <div className="loading-section">
                  <Watch
                    visible={true}
                    height="40"
                    width="40"
                    radius="48"
                    color="#4fa94d"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass="watch-load"
                  />
                  <p className="loading-text">
                    "The two most powerful warriors are patience and time." –
                    Leo Tolstoy
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <div className="greeting-section">
                  <div className="greeting-heading">
                    <h4> Hey {userName} 👋,</h4>{' '}
                  </div>{' '}
                </div>{' '}
              </Row>

              <Row>
                <div className="dashboard-header-section">
                  <div className="dashboard-header-content">
                    <Row>
                      <Col className="ds-col" xs={12} md={4}>
                        <DashboardHeaderColumn
                          imgUrl="/img/goal-achieved-2.png"
                          imgAlt="goal-acheived"
                          headerTitle="Goals Achieved"
                          bigText={`${overallProgress}%`}
                        />
                      </Col>
                      <Col className="ds-col" xs={12} md={4}>
                        <DashboardHeaderColumn
                          imgUrl="/img/groups-dashboard.png"
                          imgAlt="groups-total"
                          headerTitle="GroupSync"
                          bigText={totalGroups}
                        />
                      </Col>
                      <Col className="ds-col" xs={12} md={4}>
                        <DashboardHeaderColumn
                          imgUrl="/img/iteration.png"
                          imgAlt="goal-acheived"
                          headerTitle="Active Cycles"
                          bigText={noOfCycles}
                        />
                      </Col>
                    </Row>
                  </div>{' '}
                </div>
              </Row>
              <Row>
                <div className="table-section">
                  <div className="table-content">
                    <Row>
                      <Col>
                        <p className="table-header-title"> My Cycles</p>
                      </Col>
                      <Col className="switch-cycle">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label="Show All"
                          onChange={handleSwitchChange}
                        />
                      </Col>
                    </Row>
                    <DashboardTable cycleFlag={isAllCycles} />
                  </div>
                </div>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import './MobileBanner.css';
import { IoClose } from 'react-icons/io5';
const MobileBanner = () => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
    localStorage.setItem('mobileBannerClosed', 'true');
  };

  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const message = isIos
    ? 'Please use our iOS mobile app for a better experience.'
    : 'Please use our mobile app for a better experience.';

  if (isClosed || localStorage.getItem('mobileBannerClosed')) return null;

  return (
    <div className="mobile-banner">
      <p>{message}</p>
      <button className="close-btn" onClick={handleClose}>
        <IoClose size={24} /> {/* React Icons Close icon */}
      </button>
    </div>
  );
};

export default MobileBanner;

import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export const UserProvider = ({ children }) => {
  const storedUserId = localStorage.getItem('userId'); // Check if UserId is stored in localStorage
  const [userId, setUserId] = useState(storedUserId || '');
  const [userName, setUserName] = useState('');
  const [lastname, setLastname] = useState('');
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (userId) {
          // Make an API request to fetch User data using UserId
          const response = await axios.get(`${apiUrl}/users/${userId}`, {
            headers: {
              'x-auth-token': token,
            },
            // Add appropriate headers or authentication if required
          });

          // Assuming the response contains User's name field
          setUserName(response.data);

          const subscriptionResponse = await axios.get(`${apiUrl}/stripe`, {
            headers: {
              'x-auth-token': token,
            },
          });

          setCurrentSubscription(subscriptionResponse);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchUserDetails = async () => {
      try {
        if (userId) {
          const response = await axios.get(
            `${apiUrl}/users/${userId}/user-details`,
            {
              headers: {
                'x-auth-token': token, // Pass the auth token in the headers
              },
            },
          );
          setProfileImageUrl(response.data.profileImageUrl || '');
          setLastname(response.data.lastname || '');

          console.log(response.data.lastname);
        }

        //   console.log('response-data- ', response);

        // Set profile image URL
        // setEmail(response.data.email || '');
        //const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // setTimeZone(userTimeZone);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
    fetchUserData();
  }, [userId]);
  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        userName,
        currentSubscription,
        profileImageUrl,
        lastname,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
